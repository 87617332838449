<template>
  <b-sidebar
    id="create-investment-sidebar"
    bg-variant="white"
    no-header
    backdrop
    right
    width="70%"
  >
    <div class="investment-sidebar-wrapper">
      <b-row>
        <b-col>
          <b-form-group label="Тип графика">
            <b-form-select
              v-model="investment.schedule_type"
              :options="schedules"
              text-field="display_name"
              value-field="value"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Источник">
            <b-form-select
              v-model="investment.source"
              :options="sources"
              text-field="display_name"
              value-field="value"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Название">
            <b-form-input v-model="investment.name" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-group label="Сумма">
            <b-form-input v-model="investment.value" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Валюта">
            <b-form-select
              v-model="investment.currency"
              :options="currencies"
              value-field="value"
              text-field="display_name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Ставка">
            <b-form-input v-model="investment.rate" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-group label="Дата начала">
            <b-form-datepicker v-model="investment.date_start" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Дата окончания">
            <b-form-datepicker v-model="investment.date_end" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button variant="success" @click="createInvestment">
            Добавить
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: 'CreateInvestmentSidebar',
  data() {
    return {
      investment: {},
      sources: [],
      currencies: [],
      schedules: [],
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions() {
      const res = await this.$http.options('admin/investments/')
      this.sources = res.data.actions.POST.source.choices
      this.currencies = res.data.actions.POST.currency.choices
      this.schedules = res.data.actions.POST.schedule_type.choices
    },
    async createInvestment() {
      try {
        await this.$http.post('admin/investments/', this.investment)
        this.$emit('investmentCreated')
      } catch (e) {
        this.$bvToast.toast('Ошибка при добавлении инвестиции', {
          title: 'Ошибка',
          variant: 'danger',
        })
      }
    },
  },
}
</script>

<style lang="scss">
.investment-sidebar-wrapper {
  padding: 24px;
}
</style>
