<template>
  <div class="investments-list">
    <b-row>
      <b-col>
        <b-form-select
          v-model="selectedSource"
          :options="sources"
          value-field="value"
          text-field="display_name"
        />
      </b-col>
      <b-col>
        <b-form-datepicker v-model="startDate" />
      </b-col>
      <b-col>
        <b-form-datepicker v-model="endDate" />
      </b-col>
      <b-col class="text-right">
        <b-button v-b-toggle.create-investment-sidebar variant="success">
          Добавить
        </b-button>
      </b-col>
    </b-row>
    <b-tabs class="mt-4">
      <b-tab title="Таблица">
        <b-row>
          <b-col>
            <b-card>
              <b-table
                :items="investments"
                :fields="fields"
                empty-text="Нет данных"
                show-empty
              />
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Календарь">
        <b-card>
          <full-calendar
            ref="investmentsCalendar"
            :options="options"
            class="full-calendar"
          />
        </b-card>
      </b-tab>
    </b-tabs>
    <create-investment-sidebar
      ref="createInvestmentSidebar"
      @investmentCreated="investmentCreated"
    />
  </div>
</template>

<script>
import CreateInvestmentSidebar from '@/views/finances/components/CreateInvestmentSidebar'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
  name: 'InvestmentsList',
  components: { CreateInvestmentSidebar, FullCalendar },
  data() {
    return {
      selectedSource: '',
      startDate: '',
      endDate: '',
      sources: [],
      investments: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'value',
          label: 'Сумма',
        },
        {
          key: 'rate',
          label: 'Ставка',
        },
        {
          key: 'monthly_payment',
          label: 'Месячный платеж',
        },
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'source_display',
          label: 'Тип источника',
        },
      ],
      options: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'prev,next, title',
          end: '',
        },
        events: [
          { title: '666.67EUR', date: '2022-02-10' },
          { title: '666.67EUR', date: '2022-03-10' },
          { title: '666.67EUR', date: '2022-04-10' },
          { title: '666.67EUR', date: '2022-05-10' },
          { title: '666.67EUR', date: '2022-06-10' },
          { title: '50 000EUR', date: '2022-06-25' },
        ],
      },
    }
  },
  created() {
    this.getOptions()
    this.getInvestments()
  },
  methods: {
    async getOptions() {
      const res = await this.$http.options('admin/investments/')
      this.sources = res.data.actions.POST.source.choices
    },
    async getInvestments() {
      const res = await this.$http.get(
        `admin/investments/?source=${this.selectedSource}`
      )
      this.investments = res.data.results
    },
    investmentCreated() {
      this.$refs.createInvestmentSidebar.hide()
      this.getInvestments()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';
.fc {
  .fc-day-today {
    background: #161d31 !important;
  }
}
</style>
